import {
    originName,
    ueberNatan,
    ueberStelp,
    foodDescription,
} from "../../resources/textContent/textContent";
import OpeningTimeSection from "./OpeningTimeSection";
import Button from "../button/Button";
import Events from "../events/Events";
import React from "react";
import Heading from "../text/Heading";
import Text from '../text/Text'
import DynamicImages from "../interactiveelements/DynamicImages";
import EventGallery from "../events/EventGallery";

//this component is displaying all content on the black section of the website
function BlackSection() {
    const images = [
        require("../../resources/events/natan-pubquiz.png"),
        require("../../resources/events/natan-social-comedy-club.png"),
    ];

    const whiteIcon = {
        color: "white",
        backgroundColor: "rgba(1,1,1,0.6)",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        lineHeight: "40px",
        paddingBottom: "4px",
    };

    const foodImages = [
        require( '../../resources/food/imgCoffee.jpg'),
        require('../../resources/food/imgCake.jpg'),
        require('../../resources/food/imgDesert.jpg'),
    ]


    const isMobile = window.innerWidth <= 1330;

    return (<>
            <Heading title='ZUM WOHLE ALLER' color="white"/>
            <Text text={ueberNatan} formatierung='block' color='white'/>
            <div id="opening"/>
            <div className="sectionPadding"/>
            <OpeningTimeSection/>
            <div id='stelp'/>
            <Heading title='A PART OF THE STELP FAMILY' color="white"/>
            <Text text={ueberStelp} formatierung='block' color='white'/>
            <div id="menu"/>
            <Heading title='FOOD & DRINKS - MODERN, REGIONAL, INNOVATIV' color="white"/>
            <DynamicImages images={foodImages} className="imagefood" containerClass="container"/>
            <Text text={foodDescription} formatierung='block' color='white'/>
            <Button name='Speisekarte' styleName='Menu'/>
            <Heading title='ABIE NATHAN - DER MANN HINTER DEM NAMEN' color="white"/>
            <Text text={originName} formatierung='block' color='white'/>
            <div id="events"/>
            <Heading title='AKTUELLE EVENTS' color="white"/>
            <EventGallery images={images}
                     infinite={true}
                     centerMode={true}
                     slidesToShow={isMobile ? 1 : 2}
                     slidesToScroll={1}
                     imageHeight={isMobile ? '500px' : '600px'}
                     iconStyle={whiteIcon}
            />
            <Heading title='DEIN EVENT IM NATAN - MIETEN, FEIERN, GUTES TUN' color="white"/>
            <Events/>
            <div id="endPage" className="sectionPadding"/>
        </>
    )
}

export default BlackSection