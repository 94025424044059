import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../styles/gallery/Gallery.css';

const LogoGallery = ({ images, slidesToShow, slidesToScroll, infinite, centerMode, imageHeight, iconStyle }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const PrevArrow = ({ onClick }) => (
        <div className="custom-arrow custom-prev-arrow" onClick={onClick}>
            <span style={iconStyle}>«</span>
        </div>
    );

    const NextArrow = ({ onClick }) => (
        <div className="custom-arrow custom-next-arrow" onClick={onClick}>
            <span style={iconStyle}>»</span>
        </div>
    );

    const handleImageClick = (link) => {
        if (link) {
            window.open(link, '_blank'); // Open the external website in a new tab
        }
    };

    const settings = {
        dots: false,
        infinite: infinite,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToScroll,
        swipeToSlide: true,
        variableWidth: true,
        centerMode: centerMode,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
    };

    return (
        <div>
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index} onClick={() => handleImageClick(image.link)}>
                        <img
                            src={image.src}
                            alt={`Bild ${index + 1}`}
                            style={{ height: imageHeight }}
                        />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default LogoGallery;





