import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../styles/gallery/Gallery.css';

const EventGallery = ({ images, slidesToScroll, infinite, centerMode, iconStyle }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isWideScreen = windowWidth > 1400;

    // Determine the number of slides to show and the image height based on screen width
    const slidesToShow = isWideScreen ? 2 : 1; //change when number of poster changes (1:1 when 1 poster)
    const imageHeight = '600px';
    const PrevArrow = ({ onClick }) => (
        <div className="custom-arrow custom-prev-arrow" onClick={onClick}>
            <span style={iconStyle}>«</span>
        </div>
    );

    const NextArrow = ({ onClick }) => (
        <div className="custom-arrow custom-next-arrow" onClick={onClick}>
            <span style={iconStyle}>»</span>
        </div>
    );

    const settings = {
        dots: false,
        infinite: infinite,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToScroll,
        swipeToSlide: true,
        centerMode: centerMode && isWideScreen,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
    };

    return (
        <div>
            <style>
                {`
          .slick-slide {
            padding: 0 10px;
            display: flex;
            justify-content: center; 
            align-items: center;
            transform: translate3d(0, 0, 0)
          }
          .slick-slider .slick-track {
            transform: translate3d(0, 0, 0);
          }
        `}
                {`
          .custom-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 40px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .custom-prev-arrow {
            left: 10px;
            border-radius: 50%;
          }

          .custom-next-arrow {
            right: 10px;
            border-radius: 50%;
          }

          .custom-arrow:hover {
            color: ${"var(--secondaryColor)"};
          }
        `}
            </style>

            <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index} id='eventContainer'>
                        <img
                            src={image}
                            alt={`Bild ${index + 1}`}
                            style={{ height: 'auto', maxHeight:imageHeight, maxWidth: '100%' }}
                        />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default EventGallery;







