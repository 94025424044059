import React from "react";

export const oeffnungszeiten =
    "Mittwoch & Donnerstag: 10 Uhr - 22 Uhr \n" +
    "Freitag & Samstag: 10 Uhr - 1 Uhr  \n" +
    "Sonntag: 10 Uhr - 18 Uhr \n" +
    "Montag & Dienstag: geschlossen"

//Block über NATAN
export const ueberNatan = 'Moderne, vegetarische Küche und leckere Drinks genießen und nebenbei etwas Gutes tun – geht nicht? Geht doch! \n' +
    '\n' +
    'Bei uns treffen Café- und Barbetrieb auf soziales Engagement. Wir sind eine Non-Profit-Bar.\n' +
    '„Genießen für den guten Zweck“ – so lautet unser Motto. Der komplette Gewinn fließt in die\n' +
    'humanitären Projekte der Hilfsorganisation STELP e. V. \n' +
    '\n' +
    'Starke Partner*innen und Sponsor*innen unterstützen uns durch günstige Konditionen und unser ' +
    'Team besteht fast ausschließlich aus Ehrenamtlichen. Damit stellen wir sicher, dass jeder zusätzliche ' +
    'Euro dort ankommt, wo er am dringendsten benötigt wird: Bei den bedürftigen Menschen vor Ort.\n'

//Über Stelp

export const ueberStelp = 'STELP ist eine junge Organisation aus Stuttgart – unpolitisch, tolerant und weltoffen. Wir machen uns stark für eine Welt, in der Kinder, Frauen und Männer selbstbestimmt in Würde und Sicherheit leben und ihre Zukunft aus eigener Kraft nachhaltig gestalten können.\n' +
    '\n' +
    'In aktuell fünfzehn Ländern auf vier Kontinenten — Jemen, Bosnien, Griechenland, Ukraine, Polen, Nepal, Afghanistan, Libanon, Uganda, Kambodscha, Tansania, Kolumbien, Marokko, Syrien und der Türkei — lindern wir akute Not durch schnellen Zugang zu Lebensmitteln, Sanitärprodukten\n' +
    'und medizinischer Versorgung.\n'

export const volunteer = 'Im NATAN gibt es kein Personal, sondern nur Gastgeber*innen. Denn das Team, das in der Küche, im Service und an der Bar arbeitet, tut dies aus Überzeugung – um weltweit Menschen in Not zu unterstützen. Der allergrößte Teil unseres Personals arbeitet ehrenamtlich im NATAN. Mehr als 80 Volunteers sind regelmäßig im Einsatz und für unsere Gäste da. Sie sind das Herzstück des NATAN und geben ihre Zeit und ihre Energie, um anderen zu helfen. Dafür sind wir zutiefst dankbar. Alle zusammen für die gute Sache!\n'

//essen
export const foodDescription = 'Die Drinks im NATAN können sich sehen lassen: Mokuska Kaffee, GINSTR, Tonic Water & Co. von Thomas Henry, Weine vom Weingut Karl Haidle, Biere der Schönbuch Braumanufaktur, Kessler Hochgewächs Sekt und — last but not least — Softdrinks von Viva con Agua, Dietz, Fritz Kola, sowie Smoothies.\n' +
    '\n' +
    'Außerdem gibt es im NATAN von morgens bis spät in den Abend hinein verschiedene vegane\n' +
    'und vegetarische Speisen, die Spaß machen.\n'

export const originName = 'Der Name NATAN steht bei uns für mehr als nur fünf Buchstaben. „Ich habe es versucht“, hat der Visionär und Friedensaktivist Abie Nathan einmal über seine Arbeit gesagt — heute steht dieses Selbstverständnis als hebräische Inschrift auf seinem Grabstein: „Nissiti“. Abie hat uns inspiriert — und dem NATAN sowohl seinen Namen gegeben als auch die Idee dahinter geprägt. Es schließt sich nicht aus, das Leben zu lieben und in vollen Zügen zu genießen, sich aber gleichzeitig mit genauso viel Hingabe für andere einzusetzen. Bei\n' +
    'STELP machen wir das — genauso wie im NATAN. \n'

export const partner = 'Ohne die unzähligen Menschen und Unternehmen, die das Natan mit Engagement und viel Herzblut unterstützen, wäre der Sprung von der Idee zur Realität nicht gelungen. Fast alle sind seit Tag 1 dabei und unterstützen finanziell, durch stark vergünstigte Konditionen oder kostenlose Produkte und Dienstleistungen. Sie sind eine tragende Säule des Konzepts und ein wichtiger Grund, warum sich das NATAN zu einem Leuchtturm-Projekt von STELP entwickelt hat.\n' +
    'Wir sind dankbar und stolz, so treue und starke Partner*innen an unserer Seite zu haben!\n' +
    '\n'

export const events =
    <div>
        <p>
            Egal ob Familienfeier, After Work, Vernissage, Firmenevents, Weinproben oder Workshops –
            das NATAN kann sowohl für private, als auch für Corporate Events gemietet werden.
            Die Gewinne fließen an die Hilfsorganisation STELP.
        </p>
        <h2 className="subheading">FAKTEN</h2>
        <p>
            Größe des Gastraums | 35 qm
            <br/>
            Anzahl Personen | 40 - 50
        </p>
        <h2 className="subheading">CATERING</h2>
        <p className="blocksatz">
            Gemeinsam mit dir, und dem Anlass entsprechend, stellen wir die Speisen und Getränke für dein Event
            zusammen.
        </p>
        <h2 className="subheading">PERSONAL | RAUMMIETE</h2>
        <p className="blocksatz">
            Nach Absprache.
            <br/>
            Wir freuen uns über deine Anfrage an <a href="mailto:team@natan-cafeandbar.com" className='linkWhite'
                                                     target="_blank">
            team@natan-cafeandbar.com</a>.
        </p>
    </div>
;


