import React from 'react';
import '../index.css';
import StyleSection from "../components/mainsections/StyleSection";
import BlackSection from "../components/mainsections/BlackSection";
import WhiteSection from "../components/mainsections/WhiteSection";
import HeadingSection from "../components/mainsections/HeadingSection";
import Navbar from "../components/layout/Navbar";
import GallerySection from "../components/mainsections/GallerySection";

//the main page separates different sections of the website
function MainPage() {

    return (
        <React.Fragment>
            <Navbar/>
            <StyleSection bottomWheel={true}>
                <HeadingSection/>
                <BlackSection/>
            </StyleSection>
            <StyleSection className="blackText paddingPage">
                <WhiteSection/>
            </StyleSection>
            <StyleSection>
                <GallerySection/>
            </StyleSection>
        </React.Fragment>
    );
}


export default MainPage;
