import React from 'react'
import '../../styles/text/HeadlineSection.css'

//Styling and formatting the heading
function Heading(props) {
    return (
        <h1 className={props.color}>{props.title}</h1>
    )
}

export default Heading