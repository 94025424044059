import React from "react";
import Opening from "../text/Opening";
import {oeffnungszeiten} from "../../resources/textContent/textContent";
import Button from "../button/Button";
import wheel from '../../resources/logos/rad_weiß.png'
import Text from "../text/Text";
import Wheel from "../interactiveelements/Wheel";
import DynamicImages from "../interactiveelements/DynamicImages";

const singleImage = [
    require("../../resources/gallery/barNatan1.jpg"),
];

function OpeningTimeSection() {
    return (<div>
        <Wheel wheel={wheel} title="wheelRight"/>
        <div className="container">
            <DynamicImages images={singleImage} alt="Image 1" className="imageinteriorNatan"/>
            <div>
                <Opening title='opening'>
                    <h2>Öffnungszeiten</h2>
                    <Text text={oeffnungszeiten} formatierung='openingText' color='white'/>
                </Opening>
                <Button name="Reservierungsanfrage" styleName='Reservation'/>
            </div>
        </div>
    </div>)
}

export default OpeningTimeSection