import '../../styles/forms/PopUpContent.css'
import React, {useState} from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import de from 'date-fns/locale/de';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-time-picker/dist/TimePicker.css';
import Map from "../googlemaps/Map";
import emailjs from "emailjs-com";

/*
This component is displaying content for the pop up component. It enables making a reservation request
 */
function Reservation() {

    const SERVICE_ID = 'service_v740e7b';
    const TEMPLATE_ID = 'template_9r15prc';
    const PUBLIC_KEY = 'hYeEf2ZvtikQQT6Ti';

    
    const [submit, setSubmit] = useState(false)
    registerLocale("de", de)
    const [nameRequest, setName] = useState('')


    function check(){
        //neccessary input parameters to make a volunteer request
        const dateInput = document.getElementById("datePicker")
        const timeInput = document.getElementById("time");
        const nameInput = document.getElementById("name");
        const countInput = document.getElementById("personen");
        const mailInput = document.getElementById("mail");
        const commentInput = document.getElementById("comment");
        const checkPrivacy = document.getElementById('privacy')

        const date = dateInput.value;
        const time = timeInput.value;
        const name = nameInput.value;
        const count = countInput.value;
        const mail = mailInput.value;
        const comment = commentInput.value;
        const privacy = checkPrivacy.checked;

        //validation if mail is a real address
        const isValidMail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(mail);
        const selectedTime = parseInt(time.split(":")[0]);

        //parameter for date validation
        let selectedDateValue =  new Date(date);

        //parameters used to check if current input is true
        let nameValue;
        let timeValue = true;
        let dateValue;
        let mailValue;
        let countValue;

        if (date === "" || selectedDateValue.getDay() === 1 || selectedDateValue.getDay() === 2) {
            alert("Bitte wähle ein Datum, welches unseren Öffnungszeiten entspricht");
            dateInput.style.borderColor = 'red';
            dateValue = false;
        } else {
            dateInput.style.borderColor = 'black';
            dateValue = true;
        }

        if (time === "") {
            timeInput.style.borderColor = 'red';
        }else if (selectedTime >= 1 && selectedTime < 10) {
            alert("Bitte wähle eine Uhrzeit die den Öffnungszeiten entspricht");
            timeInput.style.borderColor = 'red';
            timeValue = false
        } else {
            timeInput.style.borderColor = 'black';
        }

        if (name === "") {
            nameInput.style.borderColor = 'red';
            nameValue = false;
        } else {
            nameInput.style.borderColor = 'black';
            nameValue = true;
            setName(name)
        }

        if (count === "" || count > 20 || count < 1) {
            countInput.style.borderColor = 'red';
            countValue = false;
        } else {
            countInput.style.borderColor = 'black';
            countValue = true;
        }

        if (mail === "") {
            mailInput.style.borderColor = 'red';
            mailValue = false
        } else if (!isValidMail) {
            mailInput.style.borderColor = 'red';
            mailValue = false;
            mailInput.value = '';
            mailInput.setAttribute('placeholder', 'Gebe eine korrekte E-Mail an');
        } else {
            mailValue = true
            mailInput.style.borderColor = 'black';
            mailInput.removeAttribute('placeholder');
        }
       
        if(!privacy){
            checkPrivacy.style.borderColor = 'red'
        }else{
            checkPrivacy.style.borderColor = 'black'
        }


        //if the inputValidation is successfull the form will automatically be sent via emailjs
        if(dateValue && timeValue && nameValue && countValue && mailValue && privacy){

            setSubmit(true)
        
            //passing the following parameters to the template
            emailjs.send(SERVICE_ID,TEMPLATE_ID,{
                date: date,
                time: time,
                name: name,
                count: count,
                mail: mail,
                comment: comment,
            },PUBLIC_KEY ).then(
                function (response) {
                    console.log("SUCCESS!", response.status, response.text);
                },
                function (error) {
                    console.log("FAILED...", error);
                }
            );
        }
    }

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const [startDate, setStartDate] = useState(tomorrow);

    const openDays = (date) => {
        const dayOfWeek = date.getDay(); // 0 = Sonntag, 1 = Montag, ..., 6 = Samstag
        return dayOfWeek === 0 || dayOfWeek === 3 || dayOfWeek === 4 || dayOfWeek === 5 || dayOfWeek === 6; // nur Mittwoch bis Sonntag
    };

    //this is rendered if the form hasn't been submitted
    const makeRequest = <div className='container'>
        <Map/>
        <div>
            <div className='formular'>
                <>
                    <h2 className='reservation-heading'>Tisch reservieren</h2>
                </>
                <>
                    <label>Wähle ein Datum * </label>
                    <DatePicker
                        id='datePicker'
                        locale="de"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat='dd MMMM yy'
                        required
                        minDate={tomorrow}
                        filterDate={openDays}
                    />
                </>
                <>
                    <label htmlFor="time">Deine Uhrzeit * </label>
                    <input type="time" id="time" name="time" required />
                </>
                <>
                    <p className='info'>Bitte beachte unsere Öffnungszeiten</p>
                </>
                <>
                    <label htmlFor="name">Name * </label>
                    <input type='text' id='name' name='fname' required></input>
                </>
                <>
                    <label htmlFor="personen">Anzahl Personen *</label>
                    <input type='number' id='personen' name='personen' required min="0" max="25"></input>
                </>
                <>
                    <label htmlFor="mail">E-Mail * </label>
                    <input type='email' id='mail' name='mail' required></input>
                </>
                <>
                    <label htmlFor="mail">Kommentar</label>
                    <input type='text' id='comment' name='comment'></input>
                </>
                <>
                    <p className='mandatory'>* Pflichtfelder</p>
                </>
            </div>
            <div className='checkbox'>
                <input type="checkbox" id="privacy" name="privacy"/>
                <label htmlFor="privacy" id="privacyLabel"> Ich habe die <a href="/privacy" className='linkBlack' target="_blank">Datenschutzerklärung</a> gelesen und verstanden * </label>
            </div>
            <>
                <button className='submit' onClick={check}>Abschicken</button>
            </>
        </div>
    </div>

    //this is rendered if the form is submitted
    const isSubmitted = <div className='sentForm'>
        <h3>Danke {nameRequest},</h3>
        <p>Deine Anfrage wurde erfolgreich
            abgeschickt und wird bearbeitet, wir schicken dir eine Mail zu um die Reservierung zu bestätigen.</p>
    </div>

    return ( submit ? isSubmitted:makeRequest);


}

export default Reservation