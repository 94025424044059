import React, { useState, useEffect, useRef } from 'react';
import '../../styles/foodanddrinks/MobileMenu.css'; // Import your CSS styles
import img1 from '../../resources/menu/speisekarte1.png'; // Import your images
import img2 from '../../resources/menu/speisekarte2.png';
import img3 from "../../resources/menu/speisekarte3.png";
import img4 from "../../resources/menu/speisekarte4.png";
import img5 from "../../resources/menu/speisekarte5.png";


const MobileMenu = (props) => {
    const [scrollY, setScrollY] = useState(0);
    const [touchStartY, setTouchStartY] = useState(null);
    const menuContainerRef = useRef(null);

    const menu = [
        {src: img1, alt: 'menu1'},
        {src: img2, alt: 'menu2'},
        {src: img3, alt: 'menu3'},
        {src: img4, alt: 'menu4'},
        {src: img5, alt: 'menu5'},
    ];

    useEffect(() => {
        // Disable body scrolling when the menu is open
        document.body.style.overflow = props.trigger ? 'hidden' : 'auto';

        // Clean up the body scroll style when the component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [props.trigger]);

    const handleTouchStart = (e) => {
        setTouchStartY(e.touches[0].clientY);
    };

    const handleTouchMove = (e) => {
        if (menuContainerRef.current) {
            const deltaY = (touchStartY - e.touches[0].clientY) * 2; // Adjust sensitivity factor here
            const newScrollTop = menuContainerRef.current.scrollTop + deltaY;

            // Add bounds to prevent scrolling beyond the content
            const maxScrollTop = menuContainerRef.current.scrollHeight - menuContainerRef.current.clientHeight;
            const minScrollTop = 0;

            // Ensure scrolling stays within bounds
            if (newScrollTop < minScrollTop) {
                menuContainerRef.current.scrollTop = minScrollTop;
            } else if (newScrollTop > maxScrollTop) {
                menuContainerRef.current.scrollTop = maxScrollTop;
            } else {
                menuContainerRef.current.scrollTop = newScrollTop;
            }

            setTouchStartY(e.touches[0].clientY);
            setScrollY(menuContainerRef.current.scrollTop);
        }
    };

    return props.trigger ? (
        <div className="mobile-menu-popup">
            <button
                className="close-btn"
                onClick={() => {
                    props.setTrigger(false);
                }}
            >
                ✕
            </button>
            <div
                className="mobile-menu-container"
                ref={menuContainerRef}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
            >
                {menu.map((item, index) => (
                    <div className="menu-page" key={index}>
                        <img
                            className="menu-image"
                            src={item.src}
                            alt={item.alt}
                        />
                    </div>
                ))}
            </div>
        </div>
    ) : null;
};

export default MobileMenu;
