import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';

const Menu = (props) => {
    const isMobile = window.innerWidth <= 900; // Define a breakpoint for mobile/desktop

    return (
        <div>
            {isMobile ? (
                <MobileMenu {...props} />
            ) : (
                <DesktopMenu {...props} />
            )}
        </div>
    );
};

export default Menu;