
import {partner, volunteer} from "../../resources/textContent/textContent";
import Button from "../button/Button";
import React from "react";
import Text from "../text/Text";
import Heading from "../text/Heading";
import '../../styles/gallery/Gallery.css';
import DynamicImages from "../interactiveelements/DynamicImages";
import '../../styles/interactiveelements/DynamicImages.css';
import LogoGallery from "../gallery/LogoGallery";

//this component is displaying all content on the white section of the website
function WhiteSection() {

    //this is responsible for showing the logos with horizontal scrolling
    /*const logos = [
        require('../../resources/partnerLogos/Porsche_Logo.png'),
        require("../../resources/partnerLogos/ginstr.png"),
        require("../../resources/partnerLogos/kessler_logo.png"),
        require("../../resources/partnerLogos/DesignPlus_Logo.png"),
        require("../../resources/partnerLogos/Mokuska.png"),
        require("../../resources/partnerLogos/Akis Getränke.png"),
        require("../../resources/partnerLogos/Biokiste Ortlieb.png"),
        require("../../resources/partnerLogos/Nast.png"),
        require("../../resources/partnerLogos/GMW_Steuerberater.png"),
        require("../../resources/partnerLogos/gourmet-kochmanufaktur_logo.png"),
        require("../../resources/partnerLogos/Leonhard-Stuttgart_Logo.png"),
        require("../../resources/partnerLogos/the-english-tearoom-logo.png"),
        require("../../resources/partnerLogos/Schönbuch.png"),
        require("../../resources/partnerLogos/Der-Blumenladen-Stuttgart_Logo.png"),
        require("../../resources/partnerLogos/Karl_haidle_weingut_logo.png"),
        require("../../resources/partnerLogos/kuchenliebe.png")
    ];
    */

    const logos = [
        {
            src: require("../../resources/partnerLogos/Porsche_Logo.png"),
            link: 'https://www.porsche.com/germany/'
        },
        {
            src: require("../../resources/partnerLogos/ginstr.png"),
            link: 'https://www.stuttgartdrygin.com/ '
        },
        {
            src: require("../../resources/partnerLogos/kessler_logo.png"),
            link: 'https://www.kessler-sekt.de/',
        },
        {
            src: require("../../resources/partnerLogos/DesignPlus_Logo.png"),
            link: 'https://www.designplus.org/'
        },
        {
            src: require("../../resources/partnerLogos/Mokuska.png"),
            link: 'https://mokuska-caffe.de/',
        },
        {
            src: require("../../resources/partnerLogos/Akis Getränke.png"),
            link: 'https://aki-s.de/'
        },
        {
            src: require("../../resources/partnerLogos/Biokiste Ortlieb.png"),
            link: 'https://www.biokiste-ortlieb.de/'
        },
        {
            src: require("../../resources/partnerLogos/Nast.png"),
            link: 'https://cafenast.com/'
        },
        {
            src: require("../../resources/partnerLogos/GMW_Steuerberater.png"),
            link: 'https://www.gmwsteuer.de/'
        },
        {
            src: require("../../resources/partnerLogos/gourmet-kochmanufaktur_logo.png"),
            link: 'https://gourmet-kochmanufaktur.de/'
        },
        {
            src: require("../../resources/partnerLogos/Leonhard-Stuttgart_Logo.png"),
            link: 'https://leik.de/'
        },
        {
            src: require("../../resources/partnerLogos/the-english-tearoom-logo.png"),
            link: 'https://www.the-english-tearoom.de/'
        },
        {
            src: require("../../resources/partnerLogos/Schönbuch.png"),
            link: 'https://www.braumanufaktur.com/'
        },
        {
            src: require("../../resources/partnerLogos/Der-Blumenladen-Stuttgart_Logo.png"),
            link: 'https://derblumenladen.net/'
        },
        {
            src: require("../../resources/partnerLogos/Karl_haidle_weingut_logo.png"),
            link: 'https://weingut-karl-haidle.de/'
        },
        {
            src: require("../../resources/partnerLogos/kuchenliebe.png"),
            link: 'https://www.kuchenliebe.de/',
        }
        ]

    //this is the style for the arrow icon for the gallery
    const blackIcon = {
        color: "var(--textColor)",
        backgroundColor: "rgba(1,1,1,0.6)",
        hoverColor: "var(--secondaryColor)",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: "4px",
        zIndex: "1",
    };

    const images = [
        require("../../resources/volunteers/volunteers2.jpg"),
        require("../../resources/volunteers/volunteers1.jpg"),
    ];

    const isMobile = window.innerWidth <= 768;

    return (
        <div>
            <div id="produkteUndPartner" className="sectionPadding"/>
            <Heading title='UNSERE PARTNER*INNEN' color='black'/>
            <div id="produkteUndPartner"/>
            <LogoGallery images={logos}
                     slidesToShow={isMobile ? 2 : 4}
                     slidesToScroll={1}
                     infinite={true}
                     centerMode={isMobile}
                     imageHeight={'100px'}
                     iconStyle={blackIcon}
            />
            <Text text={partner} formatierung='block' color='black'/>
            <div id="volunteers"/>
            <Heading title='UNSER NATAN DREAM TEAM' color='black'/>
            <DynamicImages images={images} className="imagevolunteer" />
            <Text text={volunteer} formatierung='block' color='black'/>
            <Button name='Werde Volunteer' styleName='Volunteer'/>
            <div id="footer" className="sectionPadding"/>
        </div>
    )
}

export default WhiteSection

